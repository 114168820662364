<template>
    <div class="app-container">
        <div v-if="user.user.type === 'admin'" class="filter-container" style="margin-bottom: 20px;">
            <div style="display: flex;">
                <div style="margin-right: 30px;font-size:16px;color: #606266;line-height: 40px;">活动开关</div>

                <el-switch v-model="formKeyValue.value" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" @change="handleKeyValueSave" style="margin-top: 10px;margin-right: 30px;">
                </el-switch>
            </div>
        </div>

        <div class="filter-container">
            <el-input class="filter-item" size="small" v-model="listQuery.shop_name" placeholder="请输入商家名称" style="width: 220px;" clearable/>

            <el-button class="filter-item" size="small" type="primary" @click="handleFilter">搜索</el-button>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="ID" width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="学校" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.school.school_name }}
                </template>
            </el-table-column>

            <el-table-column label="类型" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.shop_type === 1" type="primary">外卖</el-tag>
                    <el-tag v-else-if="scope.row.shop_type === 2" type="warning">商城</el-tag>
<!--                    <el-tag v-else-if="scope.row.shop_type === 3" type="danger">夜猫店</el-tag>-->
                </template>
            </el-table-column>

            <el-table-column label="商家名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.shop_name }}
                </template>
            </el-table-column>

            <el-table-column label="联系电话" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.tel }}
                </template>
            </el-table-column>

            <el-table-column label="分佣" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.channel_commission }}
                </template>
            </el-table-column>

            <el-table-column label="推广人数" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pay_count }}
                </template>
            </el-table-column>

            <el-table-column label="推广总金额" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pay_money_sum }}
                </template>
            </el-table-column>

            <el-table-column label="获得总分佣" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.commission_sum }}
                </template>
            </el-table-column>

            <el-table-column label="钱包余额" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.wallet ? scope.row.wallet.balance : "0.00" }}
                </template>
            </el-table-column>

            <el-table-column label="状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.channel_status === 1" type="success">正常</el-tag>
                    <el-tag v-else-if="scope.row.channel_status === 0" type="danger">已禁用</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="230" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button v-if="user.user.type === 'admin'" type="primary" size="mini" @click="handleChannelCommission(scope.row)">修改分佣</el-button>

                    <el-button type="danger" size="mini" @click="handleChannelStatus(scope.row)" v-if="scope.row.channel_status === 1">禁用</el-button>
                    <el-button type="success" size="mini" @click="handleChannelStatus(scope.row)" v-else>启用</el-button>

                    <el-button type="warning" size="mini" @click="handleDownloadQRCode(scope.row)">下载二维码</el-button>

                    <el-button type="success" size="mini" @click="handleOrderRecord(scope.row)">推广明细</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 修改分佣 -->
        <el-dialog title="修改分佣" :visible.sync="dialogCommissionVisible" width="60%" :close-on-click-modal="false">
            <el-form ref="formCommission" :rules="rulesCommission" :model="formCommission" label-width="150px">
                <el-form-item label="分佣" prop="channel_commission">
                    <el-input-number v-model="formCommission.channel_commission" :min="0" :precision="2"></el-input-number>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogCommissionVisible = false">取消</el-button>

                <el-button type="primary" @click="saveCommissionData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 修改分佣 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: "",
                    shop_name: "",
                },
                formKeyValue: {
                    id: '',
                    school_id: '',
                    key: '',
                    value: '',
                    remark: '',
                },
                // 修改分佣
                dialogCommissionVisible: false,
                btnLoading: false,
                formCommission: {
                    id: '',
                    channel_commission: 0,
                },
                rulesCommission: {
                    channel_commission: [{ required: true, message: '分佣不能为空', trigger: 'change' }],
                },
            };
        },
        created() {
            this.getList();
            this.getKeyValue();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
                this.getKeyValue();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/agriculturalBankActivities/channelShopList",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            getKeyValue() {
                let key = 'agriculturalBankActivitiesStatus';
                let remark = '农行活动开关：0.关闭 1.开启';
                request({
                    url: "/api/backend/keyValue/info",
                    method: "get",
                    params: {
                        school_id: this.school_id,
                        key: key,
                    }
                }).then(response => {
                    if (response.data) {
                        this.formKeyValue = response.data;
                    } else {
                        this.formKeyValue = {
                            id: '',
                            school_id: this.school_id,
                            key: key,
                            value: '0',
                            remark: remark,
                        }
                    }
                });
            },
            handleKeyValueSave() {
                request({
                    url: "/api/backend/keyValue/save",
                    method: "post",
                    data: this.formKeyValue
                }).then(() => {
                    this.$message({
                        type: "success",
                        message: "操作成功"
                    });
                    this.getKeyValue();
                });
            },
            // 修改分佣
            handleChannelCommission(row){
                this.formCommission.id = row.id
                this.formCommission.channel_commission = row.channel_commission
                this.btnLoading = false
                this.dialogCommissionVisible = true
                this.$nextTick(() => {
                    this.$refs['formCommission'].clearValidate() //清除校验结果
                })
            },
            saveCommissionData(){
                this.$refs['formCommission'].validate(valid => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/agriculturalBankActivities/channelCommission',
                            method: 'post',
                            data: this.formCommission
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogCommissionVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList()
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            // 改变渠道状态
            handleChannelStatus(row) {
                this.listLoading = true;
                request({
                    url: '/api/backend/agriculturalBankActivities/channelStatus',
                    method: 'post',
                    data: {
                        id: row.id,
                    },
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '操作成功'
                    });
                    this.getList();
                });
            },
            // 下载二维码
            handleDownloadQRCode(row) {
                request({
                    url: "/api/common/qrcode/base64",
                    method: "post",
                    data: {
                        url: process.env.VUE_APP_BASE_URL + "/wx/agriculturalBankActivities?shop_id=" + row.id,
                        logo: row.thumb
                    }
                }).then(response => {
                    let a = document.createElement("a");
                    a.href = response.data;
                    a.download = row.id + "-" + row.shop_name + ".png";
                    a.click();
                });
            },
            // 推广明细
            handleOrderRecord(row) {
                this.$router.push(`/activities/orderRecord?shop_id=${row.id}`);
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
